<template lang="pug">
.stats.fixed
  img(ref='img' src='~@/assets/img-effect/pic-3-blur.jpg' style='display: none')
  img(ref='img1' src='~@/assets/img-effect/pic-3.jpg' style='display: none')
canvas.canvas(ref='canvas')
//- .container.fixed
//-   .text(:class="{'hidden': isStart}")
//-     .t-0 EXPERIENCE THE WORLD <br> WITH OPPO SMART IMAGING
//-     span.t-1(@click='start') Explore
</template>

<script>
window.__DEBUG__ = false;

import { onBeforeUnmount, onMounted, reactive, ref } from 'vue';
import { createCombineCommand } from '@/commands/image-effect/combine/index.js';
import createREGL from 'regl';
import { loadImg, resize } from './common';

export default {
  name: 'Combine',
  setup() {
    const canvas = ref(null);
    const img = ref(null);
    const img1 = ref(null);
    const start = ref(null);
    const isStart = ref(false);

    let regl;

    onMounted(async () => {
      resize(canvas.value);

      regl = createREGL({ canvas: canvas.value });
      const imgEl = await Promise.all([img.value, img1.value].map((el) => loadImg(el)));
      const draw = createCombineCommand(regl, imgEl);

      // let lastTime = 0;

      // start.value = () => {
      //   if (isStart.value) return;
      //   isStart.value = true;
      //   regl.frame(({ time }) => {
      //     if (!lastTime) {
      //       lastTime = time;
      //     }
      //     draw({ time: time - lastTime });
      //   });
      // };

      regl.frame(({ time }) => {
        draw({ time });
      });
    });

    onBeforeUnmount(() => regl?.destroy());

    return { canvas, img, img1, start, isStart };
  },
};
</script>

<style lang="stylus" scoped>
@import './common.styl'

.container
  width 100vw
  height 100vh
  z-index 9
  display flex
  justify-content center
  align-items center
  color white
  .text
    transition all 1s
    &.hidden
      opacity 0

  .t-0
    font-size 80px
    line-height 100px
    margin-bottom 30px
  .t-1
    font-size 34px
    height 48px
    border-bottom 1px solid white
    cursor pointer
</style>
