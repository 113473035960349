import vert from './combine.vert';
import frag from './combine.frag';

const randGenerator = (n, sum) => {
  const randomW = Array(n).fill(sum / n);
  let prev = 0;
  for (let i = 0; i < n - 1; i += 1) {
    let diff = (Math.random() - 0.5) * 2 * 0.4 * randomW[i];
    randomW[i] += diff - prev;
    prev = diff;
  }
  randomW[n - 1] -= prev;
  return randomW;
};

const createDisplacementMap = ({ column = 10 } = {}) => {
  const { innerWidth } = window;
  const canvasW = innerWidth;
  const canvasH = 2;

  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  document.body.appendChild(canvas);

  canvas.width = canvasW;
  canvas.height = canvasH;
  canvas.setAttribute(
    'style',
    `
      width: ${canvasW}px;
      height: ${2}px;
      position: absolute;
      z-index: 999;
      top: -99999px;
      left: -99999px;
      opacity: 0;
    `,
  );

  const linearGradient = ctx.createLinearGradient(0, 0, canvasW, 0);
  const random = randGenerator(column, 1000).map((v) => v / 1000);
  const gap = random.reduce(
    (acc, cur, idx) => {
      if (idx > 0) {
        acc.push(acc[idx - 1] + cur);
      }
      return acc;
    },
    [random[0]],
  );

  gap.forEach((v, i) => {
    const startXR = Math.min(i === 0 ? 0 : gap[i - 1], 1);
    const endXR = Math.min(v, 1);

    linearGradient.addColorStop(startXR, 'white');
    linearGradient.addColorStop(endXR, 'black');

    ctx.fillStyle = linearGradient;
    ctx.fillRect(canvasW * startXR, 0, canvasW * random[i], 1);

    ctx.fillStyle = `rgb(${Math.round((i / gap.length) * 255)}, 0, 0)`;
    ctx.fillRect(canvasW * startXR, 1, canvasW * random[i], 1);
  });
  return canvas;
};

/**
 * @param {ReturnType<createREGL>} regl
 */
export const createCombineCommand = (regl, img) => {
  const position = [
    [-1, 1, 0],
    [-1, -1, 0],
    [1, -1, 0],
    [1, 1, 0],
  ];

  const column = 30;
  const disp = createDisplacementMap({ column });
  const devicePixelRatio = Math.min(window.devicePixelRatio, 2);

  return regl({
    vert,
    frag,
    attributes: {
      a_position: position,
    },
    uniforms: {
      u_time: (_, { time }) => time,
      u_disp: regl.texture({ data: disp }),
      u_texture1: regl.texture({ data: img[0], flipY: true }),
      u_texture2: regl.texture({ data: img[1], flipY: true }),
      u_resolution: [window.innerWidth * devicePixelRatio, window.innerHeight * devicePixelRatio],
      u_pixelRatio: devicePixelRatio,
    },
    count: position.length,
    primitive: 'triangle fan',
    blend: {
      enable: false,
      func: {
        src: 'src alpha',
        dst: 'one minus src alpha',
      },
    },
  });
};
